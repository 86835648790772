.sidebar-container {
  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 200px;
  padding-top: 10px;
  margin-top: 10px;
  background-color: var(--admin-primary-color);
  border-radius: 0 10px 0 0;
  color: var(--main-white);
  position: relative;
}

.sidebar-item {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--main-white);
  border-top: 1px solid var(--main-white);
  padding-left: 25px;
}

.logo {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-archive-date-filter {
  flex-direction: column !important;
  gap: 15px !important;
}

.archive-modal {
  min-width: 35% !important;
  min-height: 15% !important;
}

.reporting-button {
  position: absolute;
  bottom: 5%;
  left: 25%;
}

.date-filter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
}
